<template>
    <div>
        <el-form :model="form_data" :rules="formRules" :ref="form_name" :disabled="formDisabled" size="mini" label-position="right" label-width="100px">
            <el-form-item label="排课名称:" prop="title"><el-input v-model="form_data.title" style="width: 100%"></el-input></el-form-item>
            <el-form-item label="场地:" prop="playground_id">
                <el-select v-model="form_data.fitness_id" filterable placeholder="请选择健身课程"  style="width:calc(100% - 60px)" @change="onSelectFitnessChange">
                    <el-option v-for="(p) in params.fitness" :key="p.key" :label="p.value" :value="p.key"></el-option>
                </el-select>
                <span style="cursor: pointer;color:#409EFF;font-weight:bold;text-decoration-line: underline;margin-left:16px;" @click="onCopyTitleTap">复制</span>
            </el-form-item>
            <el-form-item label="限定人数:" prop="people_limit_up">
                <el-input-number v-model="form_data.people_limit_up" :step="1" :min="0" :precision="0" ></el-input-number>
                <span v-if="select_fitness && select_fitness.id" style="padding:0 16px;">上限 <span style="font-weight:bold;color:#409EFF;">{{select_fitness.people_limit_up}}</span> 人</span>
                <span style="cursor: pointer;color:#409EFF;font-weight:bold;text-decoration-line: underline" @click="onSetMaxPeopleTap">设置最大</span>
            </el-form-item>
            <el-form-item label="开始时间:">
                <el-date-picker v-model="form_data.begin_at" type="datetime" placeholder="选择日期时间" format="YYYY-MM-DD HH:mm:ss"></el-date-picker>
            </el-form-item>
<!--            <el-form-item label="开始时间:">-->
<!--                <el-date-picker v-model="form_data.end_at" type="datetime" placeholder="选择日期时间" format="YYYY-MM-DD HH:00:00"></el-date-picker>-->
<!--            </el-form-item>-->
            <el-form-item label="时长:">
                <el-input-number v-model="form_data.duration_seconds"></el-input-number>
            </el-form-item>

        </el-form>
        <div style="text-align:right;">
            <el-button v-if="action!=='view'" type="primary" @click="submitForm(form_name)" size="mini">提交编辑</el-button>
            <el-button @click="onCloseDialog" size="mini">关闭</el-button>
        </div>

    </div>
</template>

<script>
    import {getParams} from "@/api/fusion/param";
    import{getDetail,submit} from "@/api/fusion/schedule";
    import{getDetail as getFitnessDetail} from "@/api/fusion/fitness";
    // import{getDetail as getCoachDetail} from "@/api/fusion/coach";

    const param_list = ['fitness']
    export default {
        name: "schedule-detail",
        props:{
            action:{
                value:String,
                default:'add'
            },
            rowId:{
                value:String,
            }
        },
        data(){
            return{
                form_name:'schedule_detail',
                form_data:{time_range:[]},
                formRules:{},
                params:{},
                params_map:{},
                params_map_reverse:{},

                select_fitness:null,
            }
        },
        computed:{
            formDisabled(){
                return this.action === 'view' || !this.action;
            }
        },

        watch:{
        },
        created(){
            this.initPage()
        },

        methods:{
            initParams(){
                return new Promise((resolve,reject)=>{
                    getParams(param_list.join(','),'zh-CN').then(resp=>{
                        this.params = resp.data;

                        let tmp_params_map = {}
                        let tmp_params_map_reverse = {}

                        //这里再这里一下每组参数做成kv方便显示
                        let p_keys = Object.keys(this.params)
                        p_keys.forEach(pl=>{
                            console.log('pl:',pl)
                            let tmp_map = {}
                            let tmp_map_reverse = {}
                            this.params[pl].forEach(pm=>{
                                // tmp_map[pm.key]=pm.value
                                tmp_map[pm.key]={value:pm.value,color:pm.color}
                                //再调转处理一下 导入Excel参数解析用
                                tmp_map_reverse[pm.value]=pm.key
                            })
                            tmp_params_map[pl] = tmp_map
                            tmp_params_map_reverse[pl] = tmp_map_reverse
                        })

                        this.params_map = tmp_params_map
                        this.params_map_reverse = tmp_params_map_reverse
                        console.log('params_map:',this.params_map)
                        console.log('params_map_reverse:',this.params_map_reverse)
                        resolve(resp)
                    }).catch((err)=>{
                        if(err){
                            console.error(err)
                        }
                        reject(err)
                    })
                });
            },
            initPage(){
                this.initParams().then(()=>{
                    if(this.rowId>0){
                        getDetail(this.rowId).then(resp=>{
                            this.form_data = resp.data
                            this.prepareSelectFitness()
                        })
                    }
                })
            },
            onCloseDialog(){
                this.$emit("onTellDialogClose")
            },
            submitForm(formName){
                if(this.form_data.time_range && this.form_data.time_range.length == 2){
                    this.form_data.begin_at = new Date(this.form_data.time_range[0]).getTime();
                    this.form_data.end_at =  new Date(this.form_data.time_range[1]).getTime();
                }
                this.form_data.begin_at = new Date(this.form_data.begin_at).getTime()
                this.$refs[formName].validate((valid)=>{
                    if(valid){
                        submit(this.form_data).then(()=>{
                            this.$emit('onTellDialogClose')
                        })
                    }
                })
            },
            onSetMaxPeopleTap(){
                if(this.select_fitness&&this.select_fitness.id){
                    this.form_data.people_limit_up = this.select_fitness.people_limit_up;
                }
            },
            onSelectFitnessChange(e){
                let that = this
                console.log('onSelectPlaygroundChange:',e)
                getFitnessDetail(e).then(resp=>{
                    // let pd = resp.data
                    that.select_fitness = resp.data
                })
            },
            prepareSelectFitness(){
                if(this.form_data.fitness_id){
                    getFitnessDetail(this.form_data.fitness_id).then(resp=>{
                        // let pd = resp.data
                        this.select_fitness = resp.data
                    })
                }
            },
            onCopyTitleTap(){
                if(this.select_fitness&&this.select_fitness.id){
                    this.form_data.title =`${this.select_fitness.code}||${this.select_fitness.title}`
                }
            }
        }
    }
</script>

<style scoped>

</style>
